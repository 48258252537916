import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { isBlank } from "@ember/utils";
import I18n from "discourse-i18n";
import ComboBox from "select-kit/components/combo-box";
export default class RelativeTimePicker extends Component {
    _roundedDuration(duration1) {
        const rounded1 = parseFloat(duration1.toFixed(2));
        // showing 2.00 instead of just 2 in the input is weird
        return rounded1 % 1 === 0 ? parseInt(rounded1, 10) : rounded1;
    }
    get duration() {
        if (this.args.durationMinutes !== undefined) {
            return this._durationFromMinutes;
        } else {
            return this._durationFromHours;
        }
    }
    get selectedInterval() {
        if (this.args.durationMinutes !== undefined) {
            return this._intervalFromMinutes;
        } else {
            return this._intervalFromHours;
        }
    }
    get _durationFromHours() {
        if (this.args.durationHours === null) {
            return this.args.durationHours;
        } else if (this.args.durationHours >= 8760) {
            return this._roundedDuration(this.args.durationHours / 365 / 24);
        } else if (this.args.durationHours >= 730) {
            return this._roundedDuration(this.args.durationHours / 30 / 24);
        } else if (this.args.durationHours >= 24) {
            return this._roundedDuration(this.args.durationHours / 24);
        } else if (this.args.durationHours >= 1) {
            return this.args.durationHours;
        } else {
            return this._roundedDuration(this.args.durationHours * 60);
        }
    }
    get _intervalFromHours() {
        if (this.args.durationHours === null) {
            return "hours";
        } else if (this.args.durationHours >= 8760) {
            return "years";
        } else if (this.args.durationHours >= 730) {
            return "months";
        } else if (this.args.durationHours >= 24) {
            return "days";
        } else if (this.args.durationHours < 1) {
            return "mins";
        } else {
            return "hours";
        }
    }
    get _durationFromMinutes() {
        if (this.args.durationMinutes >= 525600) {
            return this._roundedDuration(this.args.durationMinutes / 365 / 60 / 24);
        } else if (this.args.durationMinutes >= 43800) {
            return this._roundedDuration(this.args.durationMinutes / 30 / 60 / 24);
        } else if (this.args.durationMinutes >= 1440) {
            return this._roundedDuration(this.args.durationMinutes / 60 / 24);
        } else if (this.args.durationMinutes >= 60) {
            return this._roundedDuration(this.args.durationMinutes / 60);
        } else {
            return this.args.durationMinutes;
        }
    }
    get _intervalFromMinutes() {
        if (this.args.durationMinutes >= 525600) {
            return "years";
        } else if (this.args.durationMinutes >= 43800) {
            return "months";
        } else if (this.args.durationMinutes >= 1440) {
            return "days";
        } else if (this.args.durationMinutes >= 60) {
            return "hours";
        } else {
            return "mins";
        }
    }
    get durationMin() {
        return this.selectedInterval === "mins" ? 1 : 0.1;
    }
    get durationStep() {
        return this.selectedInterval === "mins" ? 1 : 0.05;
    }
    get intervals() {
        const count1 = this.duration ? parseFloat(this.duration) : 0;
        return [
            {
                id: "mins",
                name: I18n.t("relative_time_picker.minutes", {
                    count: count1
                })
            },
            {
                id: "hours",
                name: I18n.t("relative_time_picker.hours", {
                    count: count1
                })
            },
            {
                id: "days",
                name: I18n.t("relative_time_picker.days", {
                    count: count1
                })
            },
            {
                id: "months",
                name: I18n.t("relative_time_picker.months", {
                    count: count1
                })
            },
            {
                id: "years",
                name: I18n.t("relative_time_picker.years", {
                    count: count1
                })
            }
        ].filter((interval1)=>!this.args.hiddenIntervals?.includes(interval1.id));
    }
    calculateMinutes(duration1, interval1) {
        if (isBlank(duration1)) {
            return null;
        }
        duration1 = parseFloat(duration1);
        switch(interval1){
            case "mins":
                // we round up here in case the user manually inputted a step < 1
                return Math.ceil(duration1);
            case "hours":
                return duration1 * 60;
            case "days":
                return duration1 * 60 * 24;
            case "months":
                return duration1 * 60 * 24 * 30; // less accurate because of varying days in months
            case "years":
                return duration1 * 60 * 24 * 365; // least accurate because of varying days in months/years
        }
    }
    @action
    onChangeInterval(interval1) {
        const minutes1 = this.calculateMinutes(this.duration, interval1);
        this.args.onChange?.(minutes1);
    }
    @action
    onChangeDuration(event1) {
        const minutes1 = this.calculateMinutes(event1.target.value, this.selectedInterval);
        this.args.onChange?.(minutes1);
    }
    static{
        template(`
    <div class="relative-time-picker">
      <input
        {{on "change" this.onChangeDuration}}
        type="number"
        min={{this.durationMin}}
        step={{this.durationStep}}
        value={{this.duration}}
        id={{@id}}
        class="relative-time-duration"
      />
      <ComboBox
        @content={{this.intervals}}
        @value={{this.selectedInterval}}
        @onChange={{this.onChangeInterval}}
        class="relative-time-intervals"
      />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
